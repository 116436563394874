@import '../../global.scss';

.windowBox {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    /* Чтобы во время перетаскивания слайда ничего не выделить внутри него */
    user-select: none;
    /* Чтобы запретить скролл страницы, если мы начали двигать слайдер по оси X */
    touch-action: pan-y;
    & span {
        display: block;
        width: 10px;
        height: 10px;
        border-bottom: 3px solid #0d6efd;
        border-left: 3px solid #0d6efd;
        margin: -2px;
        float: left;
    }
}

.slides {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    align-items: center;
    transition: translate;
    transition-property: transform;
    transition-timing-function: ease-in-out;
    & img {
        pointer-events: none;
      }
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    z-index: 2;
}

.left {
    left: 40px;
}

.left span {
    animation: left 1s infinite;
    &:nth-child(2){
        animation-delay: -0.1s;
    }
    &:nth-child(3){
        animation-delay: -0.2s;
    }
}

.right {
    right: 40px;
}

.right span {
    animation: right 1s infinite;
    &:nth-child(2){
        animation-delay: 0.1s;
    }
    &:nth-child(3){
        animation-delay: 0.2s;
    }
}

@keyframes left {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-0px,-0px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(0px,0px);
    }
}

@keyframes right {
    0%{
        opacity: 0;
        transform: rotate(-135deg) translate(-0px,-0px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(-135deg) translate(0px,0px);
    }
}