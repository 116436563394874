@import '../../global.scss';

.iconBox {
  flex: 0 0 50%;
    align-self: center;
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0;
    & img {
        width: 15%;
        height: 100%;
    }
    & img:first-child {
      border-radius: 50%;
  }
}

@media (min-width: 768px) {
  .iconBox {
    flex: 0 0 30%;
    & img {
      width: 20%;
      height: auto;
    }
  }
}

@media (min-width: 1200px) {
  .iconBox {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;
    & img {
      width: 15%;
      height: auto;
    }
  }
}