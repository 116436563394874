@import '../../global.scss';

.containerBox {
    margin-right: 5%;
    margin-left: 5%;
}

.rowBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.textBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    & h2 {
        text-align: center;
    }
}

.iconsBox {
    //flex: 1 1 30%;
    padding-left: 10px;
}

// @media (min-width: 576px) {
//     .containerBox {
//         max-width: 540px;
//     }
// }

// @media (min-width: 768px) {
//     .containerBox {
//         max-width: 720px;
//     }
// }

@media (min-width: 992px) {
    .containerBox {
        margin-right: 10%;
        margin-left: 10%;
    }

    .rowBox {
        flex-direction: row;
    }

    .iconsBox {
        flex: 1 1 40%;
    }

    .textBox {
        flex: 1 1 60%;
    }
}

// @media (min-width: 1200px) {
//     .containerBox {
//         max-width: 1140px;
//     }
// }