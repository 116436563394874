@import '../../global.scss';

.logo {
  width: auto;
  padding: 5px;
  border: solid 2px #fff;
  text-align: center;
  cursor: pointer;
}

.logo h2 {
  font-size: 18px;
  color: #0d6efd;
  &:hover {
    color: #ff5005;
  }
}

@media (min-width: 768px) {
  .logo h2 {
    font-size: 24px;
  }
}