@import '../../global.scss';

.containerBox {
    position: relative;
}

.slide {
    width: 100vh;
    background-size: 100%;
    & img {
        height: 70vh;
    }
}

@media (min-width: 768px) {
    .slide img {
        height: 100vh;
    }
}

@media (min-width: 992px) {
    .slide img {
        min-width: 100%;
        height: 100vh;
    }
}