@import '../../global.scss';

.containerBox {
    position: relative;
    width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.slide {
    box-sizing: border-box;
    height: auto;

}

@media (min-width: 1200px) {
    .containerBox {
        height: inherit;
    }
}