@import '../../global.scss';

.containerBox {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.rowBox {
    display: flex;
    flex-direction: column;
}

@media (min-width: 576px) {
    .containerBox {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .containerBox {
        max-width: 720px;
    }

    .rowBox {
        display: flex;
        flex-direction: row;
    }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
    .containerBox {
        max-width: 1140px;
    }
}