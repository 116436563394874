@import '../../global.scss';

/* mobile */
.overlay {
    opacity: 0;
    z-index: -1;
    transition: 0.5s;
}

.overlay.open {
    opacity: 1;
    z-index: 3;
}

.window {
    opacity: 0;
    z-index: -1;
    transform: scale(0.5);
    transition: 0.5s;
}

.window.open {
    opacity: 1;
    z-index: 3;
    transform: scale(1);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(128, 128, 128, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.window {
    max-width: 80%;
    max-height: 70%;
    // overflow-y: auto;
    // overflow-x: hidden;
    padding: 2%;
    background-color: #ffffff;
    position: relative;
    & img {
        height: auto;
        max-width: 100%;
    }
}

.close {
    font-size: 20px;
    color: #ffffff;
    position: absolute;
    top: -25px;
    right: -20px;
    padding: 0 2px 3px 2px;
    border: 2px solid #ffffff;
    border-radius: 30%;
    cursor: pointer;
}

@media (min-width: 576px) {
    .window {
        max-width: 75%;
    }
}

@media (min-width: 678px) {
    .window {
        max-width: 70%;
    }
}

@media (min-width: 992px) {
    .window {
        max-width: 65%;
    }
}

@media (min-width: 1200px) {
    .window {
        max-width: 60%;
    }
}