@import '../../global.scss';

.containerBox {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.arrowTop {
  position:fixed;
  bottom: 140px;
  right: 30px;
  background-color: rgb(255, 255, 255, 0.5);
  z-index: 2;
  cursor: pointer;
  & img {
    padding: 10px;
  }
}

.carouselBox {
  position: relative;
}

.mainText {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: center;
  //background-color: rgb(0, 0, 0, 0.5);
  z-index: 2;
  & h1 {
    font-size: 28px;
  }
  & h2 {
    font-size: 16px;
    color: #fff;
  }
  & p {
    display: none;
    color: #fff;
  }
}

.arrow {position: absolute;
  top: 90%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  animation: arrowDown 1s infinite;
  & img {
    opacity: 0;
  }
}

@keyframes arrowDown {
  0% {
    bottom: 45px;
  }

  50% {
    bottom: 30px;
  }

  100% {
    bottom: 15px;
  }
}

.serviceBox {
  background: #fff;
  padding: 90px 0 0 0;
}

.galleryBox {
  padding-top: 90px;
}

.aboutBox {
  background: #fff;
  padding: 90px 0 0 0;
}

.reviewsBox,
.blog {
  position: relative;
  padding: 90px 0;
  margin: 90px 0;
  background: url('../../assets/image/carousel/carousel_1_1280.webp') 50%/cover no-repeat;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(13,11,24,.6);
}

.hotels {
  background: #fff;
  padding: 90px 0;
}

.blog {
  padding-top: 90px;
}

.contact {
  background: #ffffff;
  padding-top: 0 0 90px 0;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
  width: 10px; /* ширина для вертикального скролла */
  height: 8px; /* высота для горизонтального скролла */
  background-color: #143861;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #003e85;
  border-radius: 9em;
  box-shadow: inset 1px 1px 10px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #253861;
}

@media (min-width: 576px) {
  .mainText {
        padding: 20px;
        max-width: 540px;
        & h1 {
          font-size: 50px;
        }
        & p {
          display: block;
        }
    }
}

@media (min-width: 768px) {
  .mainText {
    max-width: 720px;
  }

  .arrow img {
    width: 50px;
    height: 50px;
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .mainText {
    max-width: 960px;
  }

  .arrow img {
    width: 75px;
    height: 75px;
  }
}

@media (min-width: 1200px) {
    .mainText {
        max-width: 1140px;
    }

    .arrow img {
      width: 100px;
      height: 100px;
    }
}
