@import '../../global.scss';

.containerBox {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.titleBox {
    text-align: end;
    width: 90%;
    margin-bottom: 1%;
    z-index: 1;
    & a {
        text-align: start;
        color: white;
        font-weight: 600;
        padding: 10px;
        border-radius: 10px;
        background-color: rgba(110, 108, 108, 0.7);
        cursor: pointer;
        &:hover {
            color: rgb(255, 123, 0);
        }
    }
    margin-right: auto;
    margin-left: auto;
}

.carouselBox {
    height: 100%;
    width: 100%;
    max-width: 95%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.slide {
    box-sizing: border-box;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    height: inherit;

}

@media (min-width: 1200px) {
    .carouselBox {
        max-width: 1140px;
    }

    .titleBox {
        width: 1130px;
    }
}