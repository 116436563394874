@import '../../global.scss';

.lineBox {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 90px 0;
  }
  
  .circle {
    height: 5px;
    border-radius: 50%;
    border: 2px solid black;
    flex: 0 0 1.5%;
    margin: 0 5px;
  }
  
  .line {
    height: 2px;
    background-color: black;
    flex: 1 1 20%;
  }

@media (min-width: 992px) {
  .circle {
    height: 10px;
  }
}