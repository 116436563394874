@import '../../../../global.scss';

.containerBox {
    height: 470px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    padding-top: 43px;
    padding-bottom: 58px;
    padding-left: 45px;
    padding-right: 45px;
    text-align: center;
}

.ratingBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 18px;
    font-weight: 600;
    color: #393939;
    line-height: 1.2;
    margin-top: 21px;
}

.text {
    font-size: 16px;
    font-weight: 600;
    color: #393939;
    line-height: 1.2;
    margin-top: 24px;
    & p:last-of-type {
        margin-bottom: 0;
    }
}

.image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    & img {
        display: block;
        width: 100%;
    }
}

.auhtor {
    flex: 0 0 20px;
}

@media (min-width: 576px) {
    .containerBox {
        height: 350px;
    }
}

@media (min-width: 768px) {
    .containerBox {
        height: 450px;
    }

    .text {
        font-size: 24px;
    }

    .title {
        font-size: 24px;
    }
}