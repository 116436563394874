@import '../../../../global.scss';

.containerBox {
    margin: 0 2px;
    cursor: pointer;
}

.slide {
    position: relative;
    & h2 {
        font-size: 24px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        color: white;
        padding: 10px;
        border-radius: 10px;
        background-color: rgba(110, 108, 108, 0.5);
    }
    & img {
        width: 100%;
        height: auto;
    }
}

.cartBlogBox {
    width: 200px;
    height: 200px;
}

@media (min-width: 992px) {
    .slide h2 {
        font-size: 18px;
    }
}

@media (min-width: 1200px) {
    .slide h2 {
        font-size: 24px;
    }
}