@import '../../../../global.scss';

.containerBox {
    max-height: 65vh;
    overflow-y: auto;
}

.slide {
    & img {
        width: 100%;
    }
    & p {
        text-indent: 30px;
        &::first-letter {
            color: #0d6efd;
            font-weight: 600;
            font-size: 20px;
        }
    }
}