@import '../../global.scss';

.containerBox {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.imgBox {
    & img {
        width: 100%;
        max-height: 65vh;
    }
}