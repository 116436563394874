@import '../../global.scss';

.btn {
  min-width: 130px;
  height: 41px;
  background: #ffa37b;
  transition: all 200ms ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 5px;
  margin: 0 5px;
  & span {
    color: white;
  }
}

.contentBox {
  max-height: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  & a {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #0d6efd;
    cursor: pointer;
  }
  & a:hover {
    color: #ff5005;
  }
  & img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
}

@media (min-width: 992px) {
  .btn {
    width: 160px;
    //height: 44px;
  }
}

@media (min-width: 1200px) {
  .btn {
    width: 180px;
    height: 43px;
  }
}