@import '../../global.scss';

.containerBox {
    flex: 0 0 30%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.rowBox {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.infoBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 74px;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
    &:first-child {
        margin-top: 0;
    }
    & p {
        margin-bottom: 0;
        line-height: 1.4;
        &:last-child {
            margin: 0 0 10px 0;
        }
    }
}

.map {
    flex: 1 1 auto;
    height: 100%;
}

.advertising {
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.line {
    border: 2px dotted #0d6efd;
    margin: 2%;
}

.copyrightBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
    & p {
        font-size: 12px;
        margin: 0 2px;
    }
    & span {
        font-size: 12px;
        margin: 0 2px;
    }
    & a {
        font-size: 12px;
        margin: 0 2px;
    }
}

@media (min-width: 576px) {
    .containerBox {
        flex: 0 0 90%;
        max-width: 540px;
    }

    .infoContainer {
        flex-direction: row;
    }

    .info {
        margin: 0;
        & h3 {
            font-size: 16px;
        }
        & p {
            font-size: 14px;
        }
    }

    .advertising {
        height: auto;
        flex-direction: row;
        justify-content: center;
    }

    .copyrightBox {
        flex-direction: row;
        & p {
            font-size: 10px;
        }
        & a {
            font-size: 10px;
        }
    }
}

@media (min-width: 768px) {
    .containerBox {
        max-width: 720px;
    }

    .info {
        & p {
            font-size: 14px;
        }
    }

    .copyrightBox {
        & p {
            font-size: 12px;
        }
        & a {
            font-size: 12px;
        }
    }
}

@media (min-width: 992px) {
    .containerBox {
        max-width: 720px;
    }

    .infoBox {
        justify-content: space-between;
    }

    .info {
        & p {
            font-size: 16px;
        }
    }

    .copyrightBox {
        & p {
            font-size: 14px;
        }
        & a {
            font-size: 14px;
        }
    }
}

@media (min-width: 1200px) {
    .containerBox {
        max-width: 1140px;
    }

    .infoBox {
        flex-direction: row;
    }

    .infoContainer {
        justify-content: space-between;
        padding-right: 5%;
    }
}