@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
//reset styles
*{
	padding: 0;
	margin: 0;
	border: 0;
}
*, *:before, *:after{
	// -moz-box-sizing: border-box;
	// -webkit-box-sizing: border-box;
	-box-sizing: border-box;
}
:focus, :activ{outline: none;}
a:focus, a:activ{outline: none;}

nav, footer, header, aside{display: block;}

html, body{
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-family: 'Raleway',sans-serif;
    -webkit-font-smoothing: antialiased;
	text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
}
input, button, textarea{font-family: inherit;}

input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner {padding: 0;border: 0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}

h4, h5, h6{font-size: inherit;font-weight: 400;}

p {
    font-size: 15px;
    line-height: 2;
    font-weight: 500;
    color: #6d6d6d;
}

h1 {
	font-family: 'Lobster', cursive;
	font-size: 50px;
    line-height: 1.2;
    color: #ffffff;
    font-weight: 600;
}

h2 {
	font-size: 24px;
    line-height: 1.2;
    color: #393939;
    font-weight: 600;
}

h3 {
	font-size: 20px;
    line-height: 1.2;
    color: #393939;
    font-weight: 600;
}

input {
	width: 250px;
	height: 30px;
	font-size: 16px;
	border: 1px solid black;
	&:hover {
		background-color: #fffdfd;
	}
}

textarea {
	width: 250px;
	height: 120px;
	font-size: 16px;
	border: 1px solid black;
	&:hover {
		background-color: #e0dada;
	}
}